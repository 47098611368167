// title: Submit Your Cube!
// frameCardName: Summon the Pack
// frameCardSet: UST
// lastUpdatedDate: 2022-07-29

import React from 'react'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'

import CubeSubmission from 'src/components/cube-submission/CubeSubmission'

interface Props {
  pageContext: any
}

const SubmitYourCubePage: React.FC<Props> = (props) => {
  return (
    <Layout {...props.pageContext} accentColor="#6F659F">
      <CubeSubmission />
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Submit Your Cube! - Lucky Paper Radio',
        description:
          'Share your cube to be considered for a pack 1, pick 1 on Lucky Paper Radio.',
      }}
    />
  )
}

export default SubmitYourCubePage
