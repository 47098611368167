import * as styles from './Form.module.scss'

import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { postFormData } from 'utils/postFormData'

import * as Typography from 'components/typography'
import * as Forms from 'components/forms'

import { apiURL } from './config'

const Form: React.FC = () => {
  const [formData, setFormData] = useState<{ [key: string]: string }>({})

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  const onFormDataChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.currentTarget

    setFormData({ ...formData, [name]: value })
  }

  const valid = useMemo(() => {
    return (
      formData.name != null &&
      formData.pronouns != null &&
      formData.cubeName != null &&
      formData.link != null
    )
  }, [formData])

  const submit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      setSubmitting(true)

      const data = {
        timestamp: 'timestamp',
        ...formData,
      }

      postFormData(apiURL, data).then((result) => {
        setError(false)
        setSubmitting(false)

        if (result?.data?.result === 'success') {
          setSubmitted(true)
        } else {
          setError(true)
        }
      })
    },
    [formData],
  )

  if (submitted) {
    return (
      <div>
        <Typography.Paragraph>
          Your Cube has been submitted! Thank you for sharing!
        </Typography.Paragraph>
      </div>
    )
  }

  return (
    <form onSubmit={submit}>
      <Typography.TextBlock>
        <Forms.LargeLabel htmlFor="survey-name">Your Name</Forms.LargeLabel>
        <Forms.TextInput
          id="survey-name"
          type="text"
          name="name"
          value={formData.name || ''}
          onChange={onFormDataChanged}
          spellCheck={false}
        />
        <Forms.Hint>
          However you prefer to identify yourself to fellow cube designers.
        </Forms.Hint>

        <Forms.LargeLabel htmlFor="survey-pronouns">
          Your Pronouns
        </Forms.LargeLabel>
        <Forms.TextInput
          id="survey-pronouns"
          type="text"
          name="pronouns"
          value={formData.pronouns || ''}
          onChange={onFormDataChanged}
          spellCheck={false}
        />

        <Forms.LargeLabel htmlFor="survey-cubeName">Cube Name</Forms.LargeLabel>
        <Forms.TextInput
          id="survey-cubeName"
          type="text"
          name="cubeName"
          value={formData.cubeName || ''}
          onChange={onFormDataChanged}
          spellCheck={false}
        />

        <Forms.LargeLabel htmlFor="survey-link">Link to Cube</Forms.LargeLabel>
        <Forms.TextInput
          id="survey-link"
          type="text"
          name="link"
          value={formData.link || ''}
          onChange={onFormDataChanged}
          spellCheck={false}
        />

        <Forms.LargeLabel htmlFor="survey-comments">
          Additional Comments{' '}
          <span className={styles.optional}>(Optional)</span>
        </Forms.LargeLabel>
        <Forms.TextArea
          id="survey-comments"
          name="comments"
          value={formData.comments || ''}
          onChange={onFormDataChanged}
          spellCheck={false}
        />
        <Forms.Hint>
          Additional context that&rsquo;s relevant to the cube or anything else
          you want to share.
        </Forms.Hint>

        <button
          className={classNames(styles.submitButton, {
            [styles.submitting]: submitting,
          })}
          type="submit"
          disabled={!valid || submitting}
        >
          {!submitting && 'Submit'}
        </button>

        {error && (
          <div className={styles.error}>
            There was an error submitting the form. Try again and{' '}
            <Link to="/about/">get in touch</Link> if the problem continues.
          </div>
        )}
      </Typography.TextBlock>
    </form>
  )
}

export default Form
