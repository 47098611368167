import * as styles from './CubeSubmission.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import * as Typography from 'components/typography'

import Form from './Form'

const CubeSubmission: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <h1>
          <Typography.SecondaryHeading>
            Submit your Cube
          </Typography.SecondaryHeading>
        </h1>
        <Typography.Paragraph>
          Share your cube to be considered for a pack 1, pick 1 on{' '}
          <Link to="/podcast/">Lucky Paper Radio</Link>.
        </Typography.Paragraph>
      </div>

      <Form />

      <Typography.SecondaryDivider />

      <Typography.TextBlock>
        <Typography.Paragraph>
          Andy and Anthony frequently crack a pack from a listener submitted
          cube and discuss what they&rsquo;d pick and why. Submit yours and we
          may feature it on a future episode.
        </Typography.Paragraph>
      </Typography.TextBlock>
    </div>
  )
}

export default CubeSubmission
